.poker-actions-container {
    position: relative;
    color: white;
    font-weight: bold;
}


button {
    padding: 4px 6px;
    border: none;
    border-radius: 4px;
    background-color: #00ECB3;
    color: white;
    cursor: pointer;
    font-size: 12px;
}

/*button:hover {
    background-color: #0056b3;
}*/

.poker-actions-container {
    display: flex;
    flex-direction: column;
}

.add-action {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.add-action select {
    margin-right: 10px;
}

.add-action button {
    padding: 5px 10px;
}

.actions-list {
    display: flex;
    flex-direction: column;
}

.tag {
    display: flex;
    align-items: center;

    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #32414A;
}

.correct-action {
    background-color: #4CAF50;
    border-color: #c3e6cb;
}

.optional-action {
    background-color: #ffd60d;
    border-color: #ffeeba;
color:#333;
}

.mark-correct-button {
    margin-left: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.mark-correct-button:hover {
    background-color: #218838;
}

.mark-optional-button {
    margin-left: 5px;
    background-color: #ffc107;
    color: #333;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.mark-optional-button:hover {
    background-color: #e0a800;
}

.remove-tag-button {
    margin-left: 5px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.remove-tag-button:hover {
    background-color: #c82333;
}

/* PokerActions.css */
.poker-actions-container .slick-slider {

    margin: 0 auto;
    display: flex;
    align-items: flex-start;
}
.poker-actions-container .slick-track {
    display: flex;
}

.poker-actions-container .slick-list {

    overflow: hidden;
    width:300px;
    display: flex;
    align-items: flex-start;
}



.ActionCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #192831;
    border-radius: 5px;
    margin: 0px;
    padding: 0px;
    max-width:97px;
    font-size: 12px;
}


.playerTag{
    padding-left: 5px;
    color: #f9f9f9; /* Color del texto de las opciones */
}

.ActionCard select option {
    background-color: #192831; /* Color de fondo de las opciones */
    color: #f9f9f9; /* Color del texto de las opciones */
    border: none    ;
}

.actions-list {
    list-style-type: none;
    padding-left: 0px;
    height:140px;
    margin-top:0px;

}

.actions-list li {
     cursor: pointer;
     padding-left: 5px;
    margin-left: 3px ;
    margin-right: 3px ;
    border-radius: 4px;
    font-weight: normal;
    line-height: normal;
 }

.actions-list li:hover {
    cursor: pointer;
    background-color: #3a3a3a;
    padding-left: 5px;
    margin-left: 3px ;
    margin-right: 3px ;
    border-radius: 4px;
}

.actions-list li.selected {
    padding-left: 5px;
    margin-left: 3px ;
    margin-right: 3px ;
    color: #00ECB3;
    background-color: #3a3a3a;
    border-color: #28a745;
    border-radius: 4px;
}

.slick-prev:before, .slick-next:before{
    color: #00ECB3;
}

.remove-action-btn {
    position: relative;
    top: 0px;
    padding:0px;
    left: 45px;
    background: none;
    border: none;
    color: White;
    font-size: 15px;
    cursor: pointer;
    margin:0px;
    height:10px;
}
.remove-action-btn:hover {
    position: relative;
    padding:0px;
    top: 0px;
    left: 45px;
    background: none;
    border: none;
    color: #00ECB3;
    font-size: 15px;
    cursor: pointer;
    margin:0px;
    height:10px;
}

/* Archivo CSS */

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Fondo semitransparente */
    z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background:#000F18;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1001; /* Asegúrate de que el contenido del modal esté por encima del fondo */
}

.Modal h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 24px;
    color:white;
}

.Modal p {
    margin-bottom: 20px;
    font-size: 18px;
    color:white;
}

.Modal button {
    background-color: #00ECB3;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.Modal button:hover {
    background-color: #FFD700;
}
