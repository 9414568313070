.deals-grid-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    margin-left: 370px;
    margin-right: 20px;
}

.deals-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 columnas */
    gap: 20px; /* Espacio entre las celdas */
}

.deal-item {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.deal-item img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 15px;
}

.deal-item h3 {
    margin-bottom: 10px;
}

.deal-item p {
    margin: 5px 0;
}

.deal-item button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.deal-item button:hover {
    background-color: #45a049;
}
