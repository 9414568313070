/* PokerTable.css */
.poker-table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom:  30px;
    padding-top:  30px;
}

.poker-table {
    position: relative;
    width: 250px;
    height: 150px;
    background: transparent;/*#0E8E6C;*/
    border: 1px solid #f9f9f9;
    border-radius: 75px ;
    box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;

}

/* PokerTable.css - Añade estos estilos al archivo existente */

.table-center {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10; /* Asegúrate de que el selector se sitúe por encima de otros elementos */
}

.player-count-selector {
    padding: 4px 8px;
    border: 2px solid #fff; /* Borde blanco para destacar en el fondo verde oscuro */
    border-radius: 20px; /* Bordes redondeados para un look moderno */
    background-color: rgba(255, 255, 255, 0.8); /* Fondo ligeramente transparente */
    color: #005500; /* Color de texto que combine con el verde de la mesa */
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Sombra suave para dar profundidad */
    cursor: pointer; /* Cursor tipo puntero para indicar interactividad */
    outline: none; /* Remover el contorno que aparece al enfocar el selector */
}

.player-count-selector:hover {
    background-color: rgba(255, 255, 255, 1); /* Fondo más opaco al pasar el mouse */
    color: #002200;
}

.player-count-selector:focus {
    border-color: #ffdd55; /* Cambiar el color del borde cuando esté enfocado */
    box-shadow: 0 0 8px rgba(255, 221, 85, 0.8); /* Sombra más notoria al enfocar */
}


.player-seat {
    position: absolute;
    width: 45px;
    height: 45px;
    background: #213547;
    color:#f9f9f9;
    border-radius: 50%;
    border: 1px solid #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: background-color 0.3s ease; /* Suave transición para los cambios de color */
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.5); /* Sombra para darle volumen */
}


/* Ajustes específicos para la ubicación de los asientos en el modo 6-max */
.player-seat-0 { top: 50%; left: 0%; transform: translate(-50%, -50%); border: #747bff 2px solid;} /*SB*/
.player-seat-1 { top: 5%; left: 25%; transform: translate(-50%, -50%); border: #747bff 2px solid;} /*BB*/
.player-seat-2 { top: 5%; right: 25%; transform: translate(50%, -50%); } /*UTG*/
.player-seat-3 { top: 50%; right: 0%; transform: translate(50%, -50%);   }/*MP*/
.player-seat-4 { bottom: -25%; right: 10%; transform: translate(-50%, -50%);  }/*CO*/
.player-seat-5 { bottom: -25%; left: 10%; transform: translate(50%, -50%);border: #ffdd55 2px solid; }/*BU*/

/* Ajustes para la ubicación de los asientos adicionales en el modo 9-max */
.player-seat-6 { bottom: 65%; left: 25%; transform: translate(-50%, -50%); }/*MP2*/
.player-seat-7 { bottom: 70%; left: 50%; transform: translate(-50%, -50%); }/*MP3*/
.player-seat-8 { bottom: 65%; right: 25%; transform: translate(50%, -50%); }/*HJ*/

.legend {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color:#fff;
}

.legend-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 3px;
}

