/* Estilos generales del sidebar */
.sidebar {
    width: 350px;
    padding: 20px;
    background-color: #000F18;  /* Cambio a un color más oscuro */
    color: #ffffff;  /* Texto blanco para mejor contraste */
    font-family: 'Helvetica Neue', sans-serif;  /* Fuente más moderna */
    /*box-shadow: 2px 0 10px rgba(0,0,0,0.5);*/
position: fixed;
    height:100vh;

}

/* Estilos para los inputs */
.input {
    width: calc(100% - 16px);
    padding: 8px;
    margin: 8px 0;
    background-color: #32414A;  /* Asegúrate de que coincida con el color de fondo de la barra lateral */
    color: #fff;
    border: none;
    border-bottom: 2px solid #555;  /* Borde inferior visible */
    border-radius: 5px;
    transition: border-bottom-color 0.3s ease, box-shadow 0.3s ease;  /* Transiciones suaves */
    resize: none;
    font-size: small;

}

select option:checked {
    color: #555;
    background-color: #00ECB3; /* Color de fondo cuando está seleccionada */
}

.input:hover {
    border-bottom-color: #00ECB3;  /* Cambio de color en hover también */
}

.input:focus {
    outline: none;
    border-bottom-color: #00ECB3;  /* Color del borde inferior cuando está enfocado */
    box-shadow: 0 1px 0 0 rgba(0,123,255,0.5);  /* Sombra sutil para enfocar */
}

.txtMembershiPlan{
    color: #00ECB3;
}

/* Ajustes adicionales para asegurar la consistencia visual */
.input::placeholder {
    color: #bbb;  /* Color de placeholder más claro para contraste sutil */
}


/* Contenedor del input de tags que tiene posicionamiento relativo */
.tag-input-container {
    position: relative;
    color: #ffffff;
}



/* Transiciones para una experiencia de usuario más fluida */
.input, .tag button, .suggestionItem {
    transition: background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.2s ease;
}

.logOutButton{
    margin-top:10px;
    border: none;
    border-radius: 25px;
    background-color: #039370;
    color: white;
    cursor: pointer;
    font-size: 16px;
    width: 100px;
    transition: background-color 0.3s ease;
}

.logOutButton:hover {
    background-color: #00ECB3;
    color: #192831;
}

/* Styles for the sliding switch */

.mode-switch{
    border-bottom: 1px solid #f9f9f9;
padding:5px;
}

.txtChangeMode{
    margin-left: 10px;
    font-style: italic;
}

/* Sidebar.css */

/* Add this CSS for the switch */
.switch-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
    flex-direction: row;
    gap:10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:checked + .slider:before {
    transform: translateX(14px);
}

.switch-label {
    margin-left: 80px;
}


/* Optional: Styling for the mode label */
.mode-label {
    padding-left: 10px;
    font-size: 19px;
    font-weight:bold;
    vertical-align: middle;
    border-bottom: 1px solid #f9f9f9;
    color: #00ECB3;
}
/* Sidebar.css */
.modalUpsell {
    position: fixed; /* Cambiamos a fixed para que flote sobre toda la página */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centramos el modal */
    z-index: 30000; /* Nos aseguramos de que esté por encima de otros elementos */

    min-width: 400px;
    max-width: 800px;
    /*width: auto;  Ancho máximo del modal */
    padding: 20px; /* Añadimos un padding para el contenido */
    background-color: white; /* Color de fondo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para dar efecto de elevación */
    border-radius: 8px; /* Bordes redondeados */
    max-height: 90%;
    overflow:auto;
}

.modalUpsell-content {
    position: relative; /* Posicionamos relativo para el botón de cerrar */
    text-align: center; /* Centramos el texto */
    color: #192831;
    height: 90%;

}

.closeUpsell {
    position: absolute; /* Absoluto para estar en la esquina */
    top: -5px;
    right: -5px;
    font-size: 20px; /* Tamaño de fuente mayor */
    cursor: pointer; /* Cambiamos el cursor para indicar que es clickeable */
}

.overlay-upsell {
    position: fixed; /* Fijo para cubrir toda la pantalla */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
    z-index: 9999; /* Aseguramos que esté por detrás del modal */
}


.closeUpsell:hover,
.closeUpsell:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.upgradeButton {
    background-color: #ffd60d; /* Green */
    border: none;
    color: #000F18;
    padding: 15px 32px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.button-group {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.mode-button-study {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: grey;
    color: white;
    border-radius: 5px;
}

.mode-button-study.active {
    background-color: #00ECB3;
}

.mode-button-trainer {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: grey;
    color: white;
    border-radius: 5px;
}

.mode-button-trainer.active {
    background-color: #00ECB3;
}

.mode-button-stats {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background-color: grey;
    color: white;
    border-radius: 5px;
}

.mode-button-stats.active {
    background-color: #00ECB3;
}

.mode-label {
    margin-bottom: 20px;
}

.tooltip {

    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    font-size: small;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    z-index: 1000;
    bottom: 125%; /* Position above the button */
    left: 90%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.plan-container {
    display: flex;
    justify-content: space-around;
}

.plan-crusher-pro, .plan-crusher-premium {
    border: 2px solid;
    padding: 10px;
    width: auto;
    margin: 2%;
    box-sizing: border-box;
    border-radius: 5px;
}

.plan-crusher-pro {
    border-color: #00ECB3;
}

.plan-crusher-premium {
    border-color: #00ECB3;
}

/* Añadir un nuevo estilo para el botón Poker Crushers */
.poker-crushers-button {
    background-color: #ffd60d; /* Color de fondo del botón */
    color: white; /* Color del texto */
    border: none; /* Sin bordes */
    padding: 10px 20px; /* Espaciado interno */
    text-align: center; /* Alinear el texto al centro */
    text-decoration: none; /* Sin subrayado */
    display: inline-block; /* Mostrar el botón como un bloque en línea */
    font-size: 16px; /* Tamaño de la fuente */
    margin: 4px 2px; /* Margen alrededor del botón */
    cursor: pointer; /* Cambiar el cursor al pasar sobre el botón */
    border-radius: 4px; /* Bordes redondeados */
    position: absolute; /* Posicionar absolutamente */
    bottom: 56px; /* 16 píxeles desde el fondo del contenedor */
    left: 50%; /* Centrar horizontalmente */
    transform: translateX(-50%); /* Ajustar para centrar */
}