/* TopBar.css */
.top-bar {
    display: flex;

    position: fixed;
    top: 0;
    left: 0;
    right: 0; /* Asegura que se extienda hasta el extremo derecho de la pantalla */
    background-color: #000F18;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    padding: 10px 20px; /* Verifica que este padding no cause problemas */
    z-index: 1000;
    gap: 5px;
}


.nav-button {


    border: none;
    border-radius: 25px;
    background-color: #039370;
    color: white;
    cursor: pointer;
    font-size: 16px;
    width: 200px;
    transition: background-color 0.3s ease;
}

.nav-button:hover {
    background-color: #00ECB3;
    color: #192831;
}

.search-box {
    width: 90%;
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #ccc;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    background: #192831;
    color:white;
}

.search-box:focus {
    border-color: #00ECB3;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.dropdown-menu {
    position: absolute;
    top: 45px;
    left: 0%;
    width: 100%;
    padding-left: 25%;
    padding-right: 25%;
    max-height: 93vh;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.dropdown-menu li {
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    background-color: #192831;
    border-radius: 10px;
    color:white;
}

.dropdown-menu li:hover {
    background-color: #213547;
    color: white;
    border-radius: 10px;
    margin: 2px;
    border: 3px solid #00ECB3;
}

.dropdown-menu::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
    background-color: transparent; /* Color de fondo del scrollbar */
    border-radius: 4px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color del "thumb" del scrollbar */
    border-radius: 4px; /* Bordes redondeados */
}

.dropdown-menu::-webkit-scrollbar-thumb:hover {
    background-color: transparent; /* Color del "thumb" del scrollbar al pasar el ratón */
}

.dropdown-menu::-webkit-scrollbar-thumb:active {
    background-color: transparent; /* Color del "thumb" del scrollbar al hacer clic */
}

.dropdown-menu::-webkit-scrollbar-track {
    background-color: transparent; /* Color del "track" del scrollbar */
    border-radius: 4px; /* Bordes redondeados del "track" */
}

/* Estilo para navegadores basados en Gecko (Firefox) */
.dropdown-menu {
    scrollbar-width: thin; /* Hace el scrollbar más delgado */
    scrollbar-color: transparent transparent; /* Color del "thumb" y del "track" */
}

.dropdown-menu:hover {
    scrollbar-color: transparent transparent; /* Color del "thumb" y del "track" al pasar el ratón */
}

.hand-title {
    display: flex;
    align-items: center;

    font-weight: bold;
    margin-bottom: 5px;
    color:#f9f9f9;
}

.hand-details {
    font-size: 12px;
    color: #f9f9f9;
}

.hand-details div {
    margin: 2px 0;
}

.hand-cards {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.flop-turn-river {
    display: flex;
    align-items: center;
}

.flop-turn-river .hand-cards {
    margin-right: 15px;
}

.card {
    display: inline-block;
    width: 25px;
    height: 35px;
    margin: 0 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    line-height: 25px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 14px;
}

.modal {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #f9f9f9;
    color: white;
    animation: fadeIn 0.5s ease-in-out;
    z-index: 1001;
    background-color: #7659BA;
}

.modal.success {
    background-color: #28a745;
}

.modal.error {
    background-color: #dc3545;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.player-positions {
    margin-left: 10px;
    font-size: 14px;
    color: #fff;
    background-color: #7659BA;
    padding: 2px 8px;
    border-radius: 4px;
    white-space: nowrap; /* Prevent wrapping */
}

.modal-confirm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    padding: 20px;
    background-color: #192831;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
    text-align: center;
    color:#f9f9f9;
}

.modal-confirm p {
    margin-bottom: 20px;
    font-size: 16px;
}

.modal-confirm button {
    margin: 0 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.modal-confirm button:first-of-type {
    background-color: #4caf50; /* Verde */
    color: white;
}

.modal-confirm button:last-of-type {
    background-color: #f44336; /* Rojo */
    color: white;
}

/* Estilos para la superposición (overlay) detrás del modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.desciptionTopbarhand{
    padding-left: 5px;
    font-size: small;
    font-weight: normal;
}