
.poker-stats-wrapper {
    padding: 20px;
    color: white;
    margin-top:10px;
    margin-left: 370px;
    margin-right: 20px;

    height: 100%;
    width: 100%;

}

.poker-stats-table-wrapper-1 {
     margin-top:15px;
     max-height: 34vh;
     overflow: auto;
     box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6); /* Sombra sutil */
     display: flex;
     flex-direction: row;

 }

.poker-stats-table-wrapper-2 {
    margin-top:15px;
    /*max-height: 34vh;*/
    overflow: auto;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6); /* Sombra sutil */
    display: flex;
    flex-direction: row;

}

.insights-gpt {
    width:33%;
    margin-right: 10px;
    padding: 10px;
    font-size: small;
}

.poker-stats-table-wrapper-1::-webkit-scrollbar {
    width: 6px;
}

.poker-stats-table-wrapper-1::-webkit-scrollbar-thumb {
    background-color: #000F18; /* Color del thumb más oscuro */
    border-radius: 3px;
}

.poker-stats-table-wrapper-1::-webkit-scrollbar-thumb:hover {
    background-color: #000F18; /* Color al pasar el ratón */
}

.poker-stats-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Sombra sutil */
    background: #192831;


}


.poker-stats-table th{
    position: sticky;
    top: 0;
    background-color: #000F18;
    z-index: 1;
}

.poker-stats-table th, .poker-stats-table td {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
    font-size: 12px;
}

.poker-stats-table tr:hover{
    background: #000F18;
}

.action-container {
    display: flex;
    /*justify-content: space-around;*/
    margin-top: 1px;

}

.action-div {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    border-radius: 5px;
    padding: 2px;
    color: white;
    width: auto;
    height: 35px;
    margin-left:3px;
}

.action-div-vill {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    border-radius: 5px;
    padding: 2px;
    color: #32414A;
    width: auto;
    height: 35px;
    margin-left:3px;
    border: 1px solid #32414A;
}

.action-div-action{
    font-weight: bolder;
    text-shadow:  0px 1px 2px rgba(0, 0, 0, 0.8); /* Sombra sutil */
    font-size: 11px;
}


.action-div-freq{
    font-weight: normal;
    text-shadow:  0px 1px 2px rgba(0, 0, 0, 0.8); /* Sombra sutil */
    font-size: 10px;
}

.action-div.all-in {
    background-color: #8B0000;
}

.action-div.raise {
    background-color: #FF6347;
}

.action-div.bet33 {
    background-color: #FF6347;
}
.action-div.bet50 {
    background-color: #e2583f;
}
.action-div.bet75 {
    background-color: #c74d37;
}
.action-div.bet125 {
    background-color: #ba4834;
}

.action-div.fold {
    background-color: #4682B4;
}

.action-div.call {
     background-color: #16974b;
 }

.action-div.check {
    background-color: #16c86b;
}

.filters-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: left;
    border-radius: 5px;
    padding: 12px;
    background-color: #000F18;
    color: white;
    width: auto;
    height: 157px;
    gap:3px;
}

.filters-wrapper span {
    font-size: 12px;
    font-weight: bold;
}
/* Estilos para los inputs */
.filters-wrapper .input {
    width: calc(100% - 16px);
    padding: 8px;
    margin: 8px 0;
    background-color: #000F18;  /* Asegúrate de que coincida con el color de fondo de la barra lateral */
    color: #fff;
    border: none;
    border-bottom: 2px solid #555;  /* Borde inferior visible */
    border-radius: 5px;
    transition: border-bottom-color 0.3s ease, box-shadow 0.3s ease;  /* Transiciones suaves */
    resize: none;
    font-size: small;

}

.filters-wrapper select option:checked {
    color: #555;
    background-color: #00ECB3; /* Color de fondo cuando está seleccionada */
    border-radius: 3px;
    font-weight: bold;
}

.filters-wrapper .input:hover {
    border-bottom-color: #00ECB3;  /* Cambio de color en hover también */
    border-radius: 3px;
}

.filters-wrapper .input:focus {
    outline: none;
    border-bottom-color: #00ECB3;  /* Color del borde inferior cuando está enfocado */
    box-shadow: 0 1px 0 0 rgba(0,123,255,0.5);  /* Sombra sutil para enfocar */
    border-radius: 3px;
}

.filters-wrapper .input::-webkit-scrollbar {
    width: 6px;
}

.filters-wrapper .input::-webkit-scrollbar-thumb {
    background-color: #000F18; /* Color del thumb más oscuro */
    border-radius: 3px;
}

.filters-wrapper .input::-webkit-scrollbar-thumb:hover {
    background-color: #000F18; /* Color al pasar el ratón */
}

.selector-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: calc(100% - 16px);
}

.poker-stats-wrapper .tooltip {
z-index: 1000;
    position: relative;
    display: inline-block;
width:20px;
}

.poker-stats-wrapper .tooltip .tooltip-text {
    visibility: hidden;
    min-width: 100px;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    font-size: small;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    bottom: 0%; /* Position above the button */
    left: 0px;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1000;
}

.poker-stats-wrapper .tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 0%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
    z-index: 1000;
}

.poker-stats-wrapper .tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
    z-index: 1000;
}