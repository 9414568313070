/* StreetColumn.css */
.street-column-container {
    display: flex;
    flex-direction: column;  /* Cambia la dirección del flex a vertical */
    height: 93vh;  /* Ajusta según la altura deseada */
    width: 90%;    /*Ajusta según el ancho deseado */
    font-size: 12px;
    align-items: center; /* Centra horizontalmente */
    justify-content: center; /* Centra verticalmente */

}

.street-column-container h2{
    color:white;
}

.section {
    padding: 5px;
    /*border-bottom: 1px solid #ccc;  Línea divisoria entre secciones */
    justify-content: center;
    margin-right:3px;
    margin-left:3px;
    width:100%;

}

.section:last-child {
    border-bottom: none;  /* Elimina la línea divisoria en la última sección */
}
/* StreetColumn.css */
.board-cards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espacio entre los selectores de cartas */
    margin-top: 10px;
    height: 150px !important;
}

.board-cards-hero {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espacio entre los selectores de cartas */
    background: #33333;
    margin-bottom: 5px;
    height: 80px !important;
}

/* Estilos específicos para los botones de cartas si es necesario */
.card-button {
    padding: 5px 10px;
    margin: 2px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
}

.title{
    font-weight: bold;
    padding-left: 15px;
    color:#fff;
    padding-top:5px;

}

.streetInfo{
 margin-top:25px;
    height: 70vh;
    overflow-y: hidden; /* Agrega una barra de desplazamiento vertical si el contenido es demasiado grande */
    overflow-x: hidden; /* Oculta la barra de desplazamiento horizontal */
    padding: 0px; /* Espacio interno opcional */
    box-sizing: border-box; /* Asegura que el padding y el borde se incluyan en las dimensiones del div */
    width: 100%;
}

.streetInfo::-webkit-scrollbar {
    width: 8px; /* Ancho del scrollbar */
    background-color: #32414A; /* Color de fondo del scrollbar */
    border-radius: 4px;
}

.streetInfo::-webkit-scrollbar-thumb {
    background-color: #32414A; /* Color del "thumb" del scrollbar */
    border-radius: 4px; /* Bordes redondeados */
}

.streetInfo::-webkit-scrollbar-thumb:hover {
    background-color: #32414A; /* Color del "thumb" del scrollbar al pasar el ratón */
}

.streetInfo::-webkit-scrollbar-thumb:active {
    background-color: #32414A; /* Color del "thumb" del scrollbar al hacer clic */
}

.streetInfo::-webkit-scrollbar-track {
    background-color: #32414A; /* Color del "track" del scrollbar */
    border-radius: 4px; /* Bordes redondeados del "track" */
}

/* Estilo para navegadores basados en Gecko (Firefox) */
.streetInfo {
    scrollbar-width: thin; /* Hace el scrollbar más delgado */
    scrollbar-color: #32414A #32414A; /* Color del "thumb" y del "track" */
}

.streetInfo:hover {
    scrollbar-color: #32414A #192831; /* Color del "thumb" y del "track" al pasar el ratón */
}

.collapsible-section {
    margin-top: 20px;
    cursor: pointer;
}

.collapsible-header {
    background-color: #f1f1f1;
    padding: 0px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;

}

.collapsible-content {
    padding: 0px;
    border-top: none;
    animation: slide-down 0.3s ease-out;
}

@keyframes slide-down {
    from {
        max-height: 0;
        opacity: 0;
    }
    to {
        max-height: 1000px;
        opacity: 1;
    }
}
