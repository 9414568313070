/* CardMatrix.css */
.card-matrix {
    display: flex;
    flex-direction: column;
    margin: 5px;
    user-select: none; /* Aplica también al contenedor para asegurar */
    cursor: pointer;
    background: #32414A;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.row {
    display: flex;
}

.cell {
    padding: 3px;
    border: 0.5px solid #ddd;
    text-align: center;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Ajusta según el tamaño deseado */
    font-weight: bold;
    user-select: none; /* Evita la selección de texto */
    cursor: pointer;
    color:#f9f9f9;
    background: #192831;
}

/* Agrega esto a tu archivo CSS global o directamente en el componente con etiquetas de estilo */
/* Estilo para el botón con ícono */
.button-clear-matrix {
    background-color: transparent;
    color: #f9f9f9;
    border: none;
    padding: 0px;
    border-radius: 5px; /* Hace el botón redondo */
    cursor: pointer;
    transition: background-color 0.3s;
    width: 25px;
    height: 25px;
}

.button-clear-matrix:hover {
    background-color: transparent;
    color: #00ECB3;
}

.tooltip-matrix {
    z-index: 2000;
    position: relative;
    display: inline-block;
}

.tooltip-matrix .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #213547;
    color: #fff;
    text-align: center;
    font-size: small;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    z-index: 1000;
    bottom: 105%; /* Position above the button */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-matrix .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #213547 transparent transparent transparent;
}

.tooltip-matrix:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

