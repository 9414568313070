html, body, #root,html, .App {
  margin: 0;
  padding: 0;
  width: 100%;
  /*height: 100%;
  overflow-x: hidden;*/ /* Evita desbordamiento horizontal */
}



/* Ajustes adicionales para manejar el comportamiento responsive */
@media (max-width: 768px) {
  .App {
    flex-direction: column; /* Cambia la disposición a vertical en dispositivos móviles */
  }
}

/* App.css */
.content {
  display: flex;

  min-height: 100vh; /* Asegura que la aplicación cubra al menos toda la altura de la ventana del navegador */
  background-color: #32414A; /* Define un color de fondo suave para toda la aplicación */
  font-family: 'Roboto', sans-serif;
  padding-top: 54px; /* Ajusta según la altura de tu barra superior */

}

/* Estilos para la pantalla de carga */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  color: #333;
}