.poker-notes {
    background-color: #192831; /* Fondo oscuro para un look sofisticado */
    color: #dcdcdc; /* Texto en un gris claro para contraste */
    font-family: 'Roboto', sans-serif; /* Tipografía moderna y legible */
    font-size: 12px; /* Tamaño adecuado para la lectura */
    padding: 10px; /* Espacio interior para una mejor estructura */
    border-radius: 5px; /* Bordes redondeados sutiles */
    /*box-shadow: 0 4px 8px rgba(0,0,0,0.5);  Sombra para profundidad */
    /*margin: 5px 5px;  Margen para separar de otros elementos */
    /*border: 1px solid #333;  Borde sutil */
    line-height: 1.5; /* Espaciado de línea para facilitar la lectura */
    outline: none; /* Remover el borde al enfocar */
    height:250px;
    width: 99%;
}

.poker-notes:focus {
    border-color: #6c757d; /* Cambio de color de borde al enfocar */
}

/* Personalización de la barra de desplazamiento para mantener el estilo elegante */
.poker-notes::-webkit-scrollbar {
    width: 6px;
}

.poker-notes::-webkit-scrollbar-thumb {
    background-color: #555; /* Color del thumb más oscuro */
    border-radius: 3px;
}

.poker-notes::-webkit-scrollbar-thumb:hover {
    background-color: #777; /* Color al pasar el ratón */
}

/* Estilos para Firefox */
.poker-notes {
    scrollbar-width: thin;
    scrollbar-color: #555 #1a1a1a;
}
/*
button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.suggestions {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
}*/

.chatGPT-note{
    margin-left:0px;
    float:left;
    position:relative;
}
.chatGPT-note:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.tooltip-gpt {
    position: relative;
    display: inline-block;
}

.tooltip-gpt .tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    font-size: small;
    border-radius: 6px;
    padding: 3px;
    position: absolute;
    z-index: 1000;
    bottom: 100%; /* Position above the button */
    left: 0%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-gpt .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%; /* Arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip-gpt:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}