/* PokerTable.css */
/* MainContent.css */
.poker-trainer-container-wrapper{

    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 0px;
    overflow: hidden;
}

.poker-trainer-container {
    display: flex;            /* Utiliza Flexbox para el layout horizontal */
    flex-direction: column;
    align-items: center; /* Centra horizontalmente */
    /*justify-content: center;  Centra verticalmente */
    padding-top: 40px;            /* Añade espacio alrededor del contenedor para evitar que los elementos toquen los bordes externos */
    gap: 0px;                /* Añade un espacio entre cada columna */
    height: 100%;            /*  Ajusta la altura según sea necesario */
    width: 100%;
    padding-left: 360px;
    overflow: hidden;
}

.trainer-poker-table {
    position: relative;
    width: 807px;
    height:382px;
    background: #333333;
    border: 1px solid #333;
    border-radius: 45% / 50% ;
    box-shadow: 0 44px 88px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 4px 8px rgba(0,0,0,0.5);
}

.trainer-poker-table-inner {
    position: relative;
    width: 782px;
    height:357px;
    background: transparent;/*#0E8E6C;*/
    border: 1px solid #333333;
    border-radius: 45% / 50%;
    box-shadow: inset 0 4px 8px rgba(0,0,0,0.9);
    box-shadow: 0 44px 88px rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image:
            url('../../assets/logoPSA_3.png');
    background-size: 150px ;
    background-position: center;
    background-repeat: no-repeat;
    top:-5px;
}

.trainer-poker-table-inner::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 45% / 50% ;
    background-color: rgba(0, 15, 24, 0.8); /* Ajusta el color y la opacidad */
    pointer-events: none; /* Permite que el contenido debajo sea interactivo */
}

/* PokerTable.css - Añade estos estilos al archivo existente */

.trainer-table-center {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*z-index: 10;  Asegúrate de que el selector se sitúe por encima de otros elementos */
}


.trainer-player-seat {
    position: absolute;
    width: 150px;
    height: 60px;
    background: #213547;
    border-radius: 50px;
    border: 1px solid #f9f9f9;
    color:#f9f9f9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: background-color 0.3s ease; /* Suave transición para los cambios de color */
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.5); /* Sombra para darle volumen */
}


/* Ajustes específicos para la ubicación de los asientos en el modo 6-max */
.trainer-player-seat-0 { top: 5%; left: 5%; transform: translate(-50%, -50%);border: #747bff 2px solid;} /*SB*/
.trainer-player-seat-1 { top: -10%; left: 50%; transform: translate(-50%, -50%);border: #747bff 2px solid; } /*BB*/
.trainer-player-seat-2 { top: 5%; right: 5%; transform: translate(50%, -50%); } /*UTG*/
.trainer-player-seat-3 { bottom: -5%; right: -2%; transform: translate(50%, -50%); }/*MP*/
.trainer-player-seat-4 { bottom: -28%; right: 33%; transform: translate(-50%, -50%); }/*CO*/
.trainer-player-seat-5 { bottom: -5%; left: -18%; transform: translate(50%, -50%);  border: #ffdd55 2px solid;  }/*BU*/

/* Ajustes para la ubicación de los asientos adicionales en el modo 9-max */
.trainer-player-seat-6 { bottom: 70%; left: 25%; transform: translate(-50%, -50%); }/*MP2*/
.trainer-player-seat-7 { bottom: 70%; left: 50%; transform: translate(-50%, -50%); }/*MP3*/
.trainer-player-seat-8 { bottom: 70%; right: 25%; transform: translate(50%, -50%); }/*HJ*/

.trainer-seat-text {
    position: absolute;
    top: 5%;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.trainer-legend {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: -1;
    color:#fff;
}

.trainer-legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color:#fff;
}

.trainer-legend-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 3px;
}

.trainer-board-cards-hero {
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.trainer-controls-1 {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom:80px;
    border-radius: 3px;
    gap:10px;
    padding: 10px;
    width:60%;
}
.trainer-controls-2 {
    display: flex;            /* Utiliza Flexbox para el layout horizontal */
    flex-direction: row;
    align-items: center; /* Centra horizontalmente */
    justify-content: center; /* Centra verticalmente */
    margin-top: 180px;
    margin-bottom:0px;
    padding: 10px;
    gap:10px;
    border-radius: 10px;
    border: 1px solid #f9f9f9;
    background-color: #000F18;
    min-width: 400px;
    box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.5); /* Sombra para darle volumen */
}

.trainer-controls-1 button {
    margin: 0 10px;
    padding: 0px 20px;
    backgroundcolor: #00ECB3;
    font-weight: bolder;
    color: white;
    border: 1px solid #f9f9f9;
    border-radius: 10px;
    cursor: pointer;

}

.trainer-controls-1 button:hover {
    background-color: #0056b3; /* Color de fondo al hacer hover */
}

.trainer-controls-2 button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #00ECB3;
    font-weight: bolder;
    color: white;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
    cursor: pointer;

}

.trainer-controls-2 button:hover {
    background-color: #039370; /* Color de fondo al hacer hover */
}

.trainer-controls-1 button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.trainer-controls-1 button:not(:disabled):hover {
    background-color: #039370;
}

.villainAction{
    color: Yellow;
    font-weight: bold;
}
.heroAction{
    color: #00ECB3;
    font-weight: bold;
}

/*.modal {
    position: fixed;
    top: 10%;
    left: 10%;
    background-color: #dc3545;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border-radius: 8px;
    text-align: center;
}*/

.modal-content {
    font-size: 16px;
    color: #333;
}

.trainer-responses {
    width: 650px;
    margin-left: 20px;
    padding-top:20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: #000F18;
    height: 94vh;
}


/* Personalización de la barra de desplazamiento para mantener el estilo elegante */
.trainer-responses-container::-webkit-scrollbar {
    width: 6px;
}

.trainer-responses-container::-webkit-scrollbar-thumb {
    background-color: #555; /* Color del thumb más oscuro */
    border-radius: 3px;
}

.trainer-responses-container::-webkit-scrollbar-thumb:hover {
    background-color: #777; /* Color al pasar el ratón */
}

.trainer-responses-container{
    border: 1px solid #f9f9f9;
    height: 100%;
    padding-left: 5px ;
    border-radius: 5px;
    overflow: auto;
}


.trainer-responses h3 {
    margin-top: 0;
    color:white;
}

.trainer-responses h4 {
    margin-bottom: 5px;
    color: #00ECB3;
}

.trainer-responses p {
    width: 50%;
    margin: 5px 0;
    font-weight: bold;
    color: #f9f9f9;
}

.handNotes-trainer{
    margin-top:10px;
    color: #f9f9f9;
    font-size: 12px;
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px #000F18, 0 0 10px #000F18, 0 0 15px #f9f9f9, 0 0 20px #f9f9f9, 0 0 25px #f9f9f9;
    }
    50% {
        box-shadow: 0 0 10px #000F18, 0 0 15px #000F18, 0 0 20px #f9f9f9, 0 0 25px #f9f9f9, 0 0 30px #f9f9f9;
    }
    100% {
        box-shadow: 0 0 5px #000F18, 0 0 10px #000F18, 0 0 15px #f9f9f9, 0 0 20px #f9f9f9, 0 0 25px #f9f9f9;
    }
}

/* PokerTrainer.css
.chips-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
}

.chip {
    position: relative;
    width: 50px;
    height: 50px;
    margin: 5px;
} */

.chip img {
    width: 100%;
    height: auto;
}

.chips-container-0 {width: 100%;position: relative;top:30px;left:150px; }
.chips-container-1 {width: 100%;position: relative;top:95px;left:0px;}
.chips-container-2 {width: 100%;position: relative;top:20px;left:-80px;}
.chips-container-3 {width: 100%;position: relative;top:-55px;left:-100px;}
.chips-container-4 {width: 100%;position: relative;top:-90px;}
.chips-container-5 {width: 100%;position: relative;top:-55px;left:160px;}

.chip {
    width: 30px;
    height: 30px;
    position: absolute;
    animation: appear 0.5s ease-in-out;
    background-image: url("../../assets/chips.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

/* Estilo adicional para apilar las fichas */
.chip:nth-child(1) {
    top: 0;
    left: 0;
}
.chip:nth-child(2) {
    top: 5px; /* Ajusta estos valores según sea necesario */
    left: 5px;
}
.chip:nth-child(3) {
    top: 10px;
    left: 10px;
}
.chip:nth-child(3) {
    top: 15px;
    left: 15px;
}
.chip:nth-child(4) {
    top: 0px;
    left: 20px;
}
.chip:nth-child(5) {
    top: 5px;
    left: 25px;
}
.chip:nth-child(6) {
    top: 10px;
    left: 30px;
}
.chip:nth-child(7) {
    top: 0px;
    left: 35px;
}
.chip:nth-child(8) {
    top: 5px;
    left: 35px;
}
.chip:nth-child(9) {
    top: 10px;
    left: 35px;
}
.chip:nth-child(10) {
    top: 0;
    left: 0;
}
.chip:nth-child(11) {
    top: 5px; /* Ajusta estos valores según sea necesario */
    left: 5px;
}
.chip:nth-child(12) {
    top: 10px;
    left: 10px;
}
.chip:nth-child(13) {
    top: 15px;
    left: 15px;
}
.chip:nth-child(14) {
    top: 0px;
    left: 20px;
}
.chip:nth-child(15) {
    top: 5px;
    left: 25px;
}
.chip:nth-child(16) {
    top: 10px;
    left: 30px;
}
.chip:nth-child(17) {
    top: 0px;
    left: 35px;
}
.chip:nth-child(18) {
    top: 5px;
    left: 35px;
}
.chip:nth-child(19) {
    top: 10px;
    left: 35px;
}
/* Continúa agregando estilos para más fichas si es necesario */




@keyframes appear {
    from {
        transform: scale(0);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
.dealer-button {
    background-color: #FFD700;
    border: none;
    border-radius: 50%;
    color: black;
    font-weight: bold;
    padding: 10px 15px;
    position: absolute;
    top: -90px;
    right: -10px;
    box-shadow: 0 2px 4px rgb(185, 178, 7);
    background: radial-gradient(circle, #FFD700 20%, #FFC107 80%);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
}

.trainer-board-cards-hidden{
    padding-top:20px;
}

.statsHud{
    margin: 0 10px;
    padding-left:10px;
    background-color: transparent;
    font-weight: bolder;
    color: white;
    border: 1px solid #f9f9f9;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px
}