/* custom-styles.css */
.amplify-form-container {
    border-radius: 15px; /* Bordes redondeados */
    border: 1px solid #ccc; /* Opcional: borde del formulario */
    padding: 20px; /* Opcional: espacio interior del formulario */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* Opcional: sombra */
}

.container-portada {
    padding: 50px;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/Poker Crushers - Study mode.jpg');
    background-size: 200%;
    animation: movimiento 15s infinite linear alternate;
    position: relative;
}
.subtituloArticulo{
    color:#00ECB3;
    font-weight: bold;
}

@keyframes movimiento {
    from {
        background-position: bottom left;
    }
    to {
        background-position: top right;
    }
}

.appTitle{
    font-size: 60px;
}

.capa-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #0d1926;
    opacity: 0.5;
}

.container-details {
    width: 100%;
    max-width: 1200px;
    position: relative;
    color: white;
    padding-left: 50px;
}

.details {
    width: 100%;
    max-width: 1000px;
    margin-top: 150px;
}

.desktop-menu {
    display: flex;
    gap:10px;
}

.mobile-menu-icon {
    display: none;
}

.mobile-menu {
    display: none;
}

@media (max-width: 768px) {
    .container-details {
        padding-left: 20px;
    }
    .details {
        margin-top: 50px;
    }
    .details Text {
        font-size: 2rem;
    }
    button {
        width: 200px;
        height: 40px;
        font-size: 1rem;
    }
    .desktop-menu {
        display: none;
    }
    .mobile-menu-icon {
        display: block;
    }
    .mobile-menu {
        display: flex;
        flex-direction: column;
    }


}
